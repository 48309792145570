<template>
  <div class="details">
    <TopBack :title="$t('home.noticeDetail')"></TopBack>
    <div class="title">{{noticeContent.title}}</div>
    <div class="time" v-if="locale==='en-US'"> {{toHHmmss(noticeContent.createTime,-5)}}</div>
    <div class="time" v-else>{{toHHmmss(noticeContent.createTime,+8)}}</div>
    <div class="content">{{noticeContent.content}}</div>
  </div>
</template>
<script setup>
import {getCurrentInstance, ref} from 'vue'
import TopBack from './components/TopBack'
import {toHHmmss} from '../utils/decimalTool'
import {useI18n} from "vue-i18n";
const { $t } = getCurrentInstance().proxy;
const { locale } = useI18n()
const noticeContent=ref(JSON.parse(sessionStorage.getItem('noticeContent')) )
</script>
<style scoped lang="less">
  .details{
    padding: 0 18px;
    .title{
      font-weight: bold;
      font-size: 20px;
      color: #6FFEE8;
      margin:15px 0;
      text-align: left;
    }
    .time{
      font-weight: 500;
      font-size: 14px;
      color: #7E8D95;
      text-align: left;
    }
    .content{
      margin: 30px 0;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 25px;
      text-align: left;
    }
  }
</style>
